import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getNewArrivalsList } from './actions';
export default function loadNewArrivals() {
  var partTypeSlug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var newArrivalsStore = useSelector(function (state) {
    return state.parts.inventory.newArrivalsList;
  });
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    // already doing, no need to fetch again
    if (newArrivalsStore.doing) return;
    dispatch(getNewArrivalsList(tokenCache, {
      partTypeSlug: partTypeSlug
    }));
  }, [partTypeSlug]);
  return null;
}